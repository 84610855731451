<template>
  <v-container
    v-if="routingFeatures && routingFeatures.length"
    class="pt-0 pb-3 px-3 d-flex align-baseline"
  >
    <v-select
      v-model="selected"
      :label="$t('Passing through')"
      :items="items"
      hide-details
      clearable
    >
    </v-select>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          x-small
          class="ml-2 mr-1"
          :color="wheelchair ? 'primary' : undefined"
          @click="setWheelchair(!wheelchair)"
          tabindex="-1"
          v-on="on"
        >
          <v-icon>mdi-wheelchair-accessibility</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('Wheelchair') }}</span>
    </v-tooltip>
  </v-container>
</template>

<style scoped>
.v-btn:focus:before {
  opacity: 0;
}
</style>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import constants from '~/shared/constants';

export default {
  name: 'RoutingPanel',
  props: ['map'],

  data() {
    return {};
  },

  computed: {
    ...mapGetters('routing', ['wheelchair', 'vtr', 'routingFeatures']),
    ...mapGetters('site', ['editedSite']),
    items() {
      return Object.entries(constants.VTR).map(([value, text]) => ({ text: this.$t(text), value }));
    },

    selected: {
      get() {
        return this.vtr;
      },
      set(value) {
        this.setVTR(value);
      }
    }
  },

  watch: {
    routingFeatures() {
      this.map.getSource('routing').setData({
        type: 'FeatureCollection',
        features: this.routingFeatures
      });
    },

    editedSite() {
      this.clearRouting();
    }
  },

  mounted() {
    if (!this.map.getSource('routing')) {
      this.map.addSource('routing', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      this.map.addLayer({
        id: 'routing-endpoints',
        source: 'routing',
        type: 'symbol',
        layout: {
          'icon-image': '{icon}',
          'icon-allow-overlap': true
        },
        paint: {}
      });
      this.map.addLayer({
        id: 'routing-route',
        source: 'routing',
        type: 'line',
        paint: {
          'line-color': '#fca311',
          'line-width': 8
        }
      });
    }
  },

  methods: {
    ...mapActions('routing', ['setWheelchair', 'setVTR']),
    ...mapMutations('routing', ['clearRouting'])
  },

  components: {}
};
</script>
